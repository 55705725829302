import Link from 'next/link';
import styled, { css } from 'styled-components';

import { Container } from '@/styles/global';

export const Heading = styled.div`
    text-align: center;
`;

export const Filters = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    margin-top: 1.75rem;
    flex-wrap: wrap;
    flex-direction: column;

    h4 {
        display: flex;
        align-items: center;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        margin-top: 3.75rem;
        flex-wrap: wrap;
        flex-direction: row;
    }
`;

export const Carousel = styled.div`
    display: flex;
    margin: 1.875rem 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        margin: 3rem 0;
    }
`;

export const CardWrap = styled.div`
    padding: 0.875rem;
    flex: 0 0 80%;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        flex: 0 0 33.33%;
    }
`;

export const Card = styled(Link)<{ odd?: string }>`
    background-color: white;
    height: 100%;
    border-radius: 0.75rem;
    padding: 2.5rem 2.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    color: ${({ theme }) => theme.colours.text.default};
    transition: all 0.3s ease-in-out;

    svg path {
        transition: fill 0.3s ease-in-out;
    }

    h3 {
        margin-bottom: 1.3rem;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colours.surface.deepBlue};
        color: ${({ theme }) => theme.colours.text.dark.default};

        svg path {
            fill: ${({ theme }) => theme.colours.text.dark.default};
        }
    }
`;

export const Select = styled.select`
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    color: ${({ theme }) => theme.colours.text.default};
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1_43880' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='19' height='11'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.187744 0.0966797H18.1365V10.7633H0.187744V0.0966797Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1_43880)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6539 0.372348C16.993 0.00479022 17.5429 0.00479022 17.8821 0.372348C18.2212 0.739906 18.2212 1.33582 17.8821 1.70338L9.7762 10.4877C9.60662 10.6714 9.38437 10.7633 9.16209 10.7633C9.04435 10.7633 8.93204 10.7379 8.82966 10.6919C8.72799 10.6463 8.63267 10.5789 8.54982 10.4897C8.5492 10.489 8.54858 10.4883 8.548 10.4877M8.54756 10.4872L0.442123 1.70338C0.102951 1.33582 0.102951 0.739906 0.442123 0.372348C0.781294 0.00479022 1.33111 0.00479022 1.67028 0.372348L8.2936 7.54995L9.16206 8.49117L10.0306 7.54995L16.6539 0.372348' fill='%2328939C'/%3E%3C/g%3E%3C/svg%3E%0A");

    &::-ms-expand {
        display: none;
    }
`;

export const IconRow = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
    gap: 0.3rem;
`;

export const CircleCTA = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colours.surface.brandHighlight};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
    margin-top: 1rem;

    svg path {
        transition: fill 0.3s ease-in-out;
    }

    ${Card}:hover & {
        background-color: ${({ theme }) => theme.colours.surface.default};
        svg path {
            fill: ${({ theme }) => theme.colours.surface.deepBlue};
        }
    }
`;

export const NoResults = styled(Container)`
    text-align: center;
    margin-top: 3rem;
`;
