import { ILatestJob } from '@/interfaces/jobs';

import Arrow from '../../components/Icons/Arrow.svg';
import Clock from '../../components/Icons/Clock.svg';
import Pin from '../../components/Icons/Pin.svg';
import { CardWrap, Card, IconRow, CircleCTA } from './styles';

interface IJob {
    job: ILatestJob;
    odd?: boolean;
}
const JobCard = ({ job, odd }: IJob) => {
    const { title, employment_type, location, shortcode } = job;
    return (
        <CardWrap>
            <Card href={`/job/${shortcode}`} odd={odd ? 'odd' : 'even'}>
                <div>
                    <h3>{title}</h3>
                    {location && (
                        <IconRow>
                            <Pin width={24} height={24} />
                            <span>
                                {location.city && `${location.city}, `}
                                {location.country_code}
                            </span>
                        </IconRow>
                    )}
                    {employment_type && (
                        <IconRow>
                            <Clock width={24} height={24} />
                            <span>{employment_type}</span>
                        </IconRow>
                    )}
                </div>
                <CircleCTA>
                    <Arrow width={16} height={16} />
                </CircleCTA>
            </Card>
        </CardWrap>
    );
};

export default JobCard;
