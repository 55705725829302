import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { useInView } from 'react-intersection-observer';

import ScrollReveal from '@/components/ScrollReveal';
import { ILatestJob } from '@/interfaces/jobs';
import { ILatestJobsBlock } from '@/interfaces/pages';
import { Button, Container, Flex, Section, SliderButton, SliderControls } from '@/styles/global';
import { JobsContext } from 'src/context/jobsContext';
import useWindowSize, { Size } from 'src/hooks/useWindowSize';

import { Dropdown } from './dropdown';
import JobCard from './JobCard';
import { Carousel, Filters, Heading, NoResults } from './styles';

interface ILatestJobs {
    content: ILatestJobsBlock;
}

export const ALL_FILTER = 'all';

const LatestJobs = ({ content }: ILatestJobs) => {
    const { heading, background, numRecords } = content;
    const { jobs, departments, locations } = useContext(JobsContext);
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            loop: true,
            containScroll: 'keepSnaps',
        },
        [Autoplay()]
    );

    const [filters, setFilters] = useState({ department: ALL_FILTER, location: ALL_FILTER });

    const [sliderActive, setSliderActive] = useState(false);
    const size: Size = useWindowSize();

    const departmentsFilter = [ALL_FILTER, ...departments];
    const locationsFilter = [ALL_FILTER, ...locations];

    const onFilterChange = useCallback((ev) => {
        const updatedName = ev.target.name;
        const updatedValue = ev.target.value;
        setFilters((oldFilters) => {
            const newFilters = { ...oldFilters };
            newFilters[updatedName] = updatedValue;
            return newFilters;
        });
    }, []);

    let results;

    if (jobs) {
        results = useMemo(
            () =>
                jobs?.filter(
                    (job) =>
                        (filters.location === ALL_FILTER || filters.location === job.location.country) &&
                        (filters.department === ALL_FILTER || filters.department === job.department)
                ),
            [jobs, filters]
        ).slice(0, numRecords);
    }

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        const numResults = results ? results.length : 0;
        const isMobile = size.width < 720;
        const isActive = numResults > 3 || isMobile;
        setSliderActive(isActive);
    }, [size.width, results]);

    useEffect(() => {
        if (inView) {
            document.body.className = background;
        }
    }, [inView, background]);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollPause = useCallback(() => emblaApi && emblaApi.plugins().autoplay.stop(), [emblaApi]);

    if (!jobs) return <>Loading</>;

    return (
        <Section ref={ref}>
            <ScrollReveal>
                <Container>
                    <Heading>
                        <h2>{heading}</h2>
                    </Heading>
                    <Filters>
                        <h3>Search jobs</h3>
                        <Dropdown
                            placeholder="Location"
                            name="location"
                            value={filters.location}
                            options={locationsFilter}
                            onChange={onFilterChange}
                        />
                        <Dropdown
                            placeholder="Team"
                            name="department"
                            value={filters.department}
                            options={departmentsFilter}
                            onChange={onFilterChange}
                        />
                        <Button arrow="true" color="blue" href="/jobs">
                            Go search
                        </Button>
                    </Filters>
                </Container>
                {results.length === 0 ? (
                    <NoResults>We don&apos;t have any current vacancies, please check back soon</NoResults>
                ) : (
                    <Container padding={false}>
                        <div className="embla" ref={sliderActive ? emblaRef : null}>
                            <Carousel>
                                {results.map((job: ILatestJob, idx: number) => (
                                    <JobCard key={job.id} job={job} odd={idx % 2 !== 0} />
                                ))}
                            </Carousel>
                        </div>
                        {sliderActive && (
                            <SliderControls>
                                <SliderButton variant="prev" onClick={scrollPrev} aria-label="Previous" />
                                <SliderButton variant="pause" onClick={scrollPause} aria-label="Pause" />
                                <SliderButton variant="next" onClick={scrollNext} aria-label="Next" />
                            </SliderControls>
                        )}
                        <Flex justify="center" align="center">
                            <Button arrow="true" color="blue" href="/jobs">
                                Go to all jobs
                            </Button>
                        </Flex>
                    </Container>
                )}
            </ScrollReveal>
        </Section>
    );
};

export default LatestJobs;
