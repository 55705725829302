import { capitalise } from '@/utils/Capitalise';

import { Select } from './styles';

import { ALL_FILTER } from '.';

export const Dropdown = ({ placeholder, name, value, options, onChange, ...rest }) => (
    <Select
        name={name}
        value={value}
        {...rest}
        onChange={onChange}
        placeholder={value === placeholder ? capitalise(placeholder) : value}
    >
        {options.map((option) => (
            <option key={`${name}--${option}`} value={option}>
                {option === ALL_FILTER ? capitalise(placeholder) : option}
            </option>
        ))}
    </Select>
);
